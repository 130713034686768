import Vue from 'vue'
import Router from 'vue-router'
import App from './App'

import Eagle, { Options } from 'eagle.js'
import hljs from 'highlight.js'

import slideshow from './slideshows/kubees/00_Introduction'
/* eslint-disable no-new */

Vue.use(Eagle)
Vue.use(Router)
Vue.config.productionTip = false
Options.hljs = hljs

let routes = []
routes.push({ path: '/', component: slideshow })

let router = new Router({
  routes
})

new Vue({
  el: '#app',
  router,
  render: h => h('App'),
  components: { App }
})
