<template lang="pug">
#Kubees.eg-theme-agrume
  .eg-slideshow
    slide(enterNext='fadeIn' leaveNext='fadeOutLeft' leavePrev='fadeOutRight' enterPrev='fadeInLeft')
      .center.frontpage
        h1 Kubees
        h4 Kubernetes audit and compliance as a service
      .logo.initial
        include ./assets/logo.svg

    slide(steps=3 enterNext='fadeInRight' leaveNext='fadeOutUp' leavePrev='fadeOutRight' enterPrev='fadeInDown')
      .center.frontpage
        h1 Qui sommes nous
        .parent
          .bee._0
            include ./assets/bee.svg
          .bee._1
            include ./assets/bee.svg
          .bee._2
            include ./assets/bee.svg
          .bee._3
            include ./assets/bee.svg
          .var_logo.devops(v-if='(step == 1)')
            include ./assets/logo.svg
          .var_logo.devops(v-if='(step == 2)')
            include ./assets/devops.svg
          .var_logo.kube(v-if='(step == 3)')
            include ./assets/kubernetes-stacked-black.svg

    //- KUBERNETES
    slide(steps=9 enterNext='slideInDown' leaveNext='fadeOutDown' leavePrev='slideOutLeft' enterPrev='fadeIn')
      h1 Kubernetes
      .kube_parent
        .center.frontpage
          .toright_kube(v-if='(step == 9)')
            eg-transition(enter='FadeIn')
              chart(:data='figureDataKubeAdopt(step)', :options='figureOptionsKubeAdopt', type='doughnut',
                :height='650' :width='650')
        .kube_center_ico(v-if='(step >= 1) && (step < 8)')
          include ./assets/kubernetes-stacked-black.svg
        eg-transition(enter='bounceInLeft' leave='fadeOutLeft')
          .container(v-if='(step >= 2) && (step < 8)')
            include ./assets/container.svg
        eg-transition(enter='bounceInRight' leave='fadeOutRight')
          .hardware(v-if='(step >= 3) && (step < 8)')
            include ./assets/hardware.svg
        eg-transition(enter='bounceInRight' leave='fadeOutRight')
          .api(v-if='(step >= 4) && (step < 8)')
            include ./assets/api.svg
        eg-transition(enter='bounceInLeft' leave='fadeOutLeft')
          .code(v-if='(step >= 5) && (step < 8)')
            include ./assets/code.svg
        eg-transition(enter='bounceInLeft' leave='fadeOutLeft')
          .yaml(v-if='(step >= 6) && (step < 8)')
            include ./assets/yaml.svg
        eg-transition(enter='bounceInRight' leave='fadeOutRight')
          .lifecycle(v-if='(step >= 7) && (step < 8)')
            include ./assets/lifecycle.svg

    //- ENVIRONMENT
    slide(:steps=6, enter='bounceInDown' leave='slideOutLeft')
      h3 Où vous pouvez installer des clusters Kubernetes ?
      .env_parent
        eg-transition(enter='fadeIn' leave='fadeOutLeft')
          .onprem(v-if='step >=2 &&  step < 5')
            img(src='./assets/cloud.svg',:style="{height: '4em', class: 'center'}")
            p Cloud
        eg-transition(enter='fadeIn' leave='fadeOutDown')
          .cloud(v-if='step >=3 &&  step < 5')
            img(src='./assets/datacenter.svg',:style="{height: '4em'}")
            p On premise
        eg-transition(enter='fadeIn' leave='fadeOutRight')
          .hybrid(v-if="step >= 4 &&  step < 5")
            img(src='./assets/onpremises.svg',:style="{height: '4em'}")
            p Hybride
      .toright_env
        eg-transition(enter='bounceInUp')
          .center.frontpage(v-if="step >= 6")
            chart(:data='figureDataKubeEnv(step)', :options='figureDataKubeEnvD', type='doughnut',
                  :height='650' :width='650')


    //- SECURITY
    slide(enter='slideInRight' leave='fadeOutDown')
      .center.frontpage
        h1 Sécurité ?
      .env_parent_2
          eg-transition(enter='bounceInUp')
            .center.frontpage
              chart(:data='figureDataKubeSec(step)', :options='figureOptionsKubeSec', type='doughnut',
                    :height='650' :width='650')

    //- KUBEES
    slide(steps=7 enter='fadeInDown' leave='fadeOutDown')
      .center.frontpage
        h1 Kubees
      .kubees_parent
        eg-transition(enter='fadeInLeft' leave='fadeOutLeft')
          .d_plugin(v-if='step >= 2')
            include ./assets/plugin.svg
        eg-transition(enter='fadeInRight' leave='fadeOutRight')
          .d_audit(v-if='step >= 4')
            include ./assets/audit.svg
        eg-transition(enter='fadeInLeft' leave='fadeOutLeft')
          .d_api(v-if='step >= 3')
            include ./assets/api.svg
        eg-transition(enter='fadeInRight' leave='fadeOutRight')
          .d_carto(v-if='step >= 5')
            include ./assets/compass.svg
        eg-transition(enter='fadeInRight' leave='fadeOutRight')
          .d_conf(v-if='step >= 6')
            include ./assets/yaml.svg
        eg-transition(enter='fadeInRight' leave='fadeOutRight')
          .d_infra(v-if='step >= 7')
            include ./assets/hardware.svg

    //- ARCHITECTURE
    slide(steps=6 enter='bounceInDown' leave='bounceOutDown')
      h3 Architecture
      eg-transition(v-if="step==1" enter='fadeIn' leave='fadeOut')
        .architecture.initial
          include ./assets/architecture.svg
      eg-transition(v-if="step==2" enter='fadeIn' leave='fadeOut')
        .architecture.intro
          include ./assets/architecture.svg
      eg-transition(v-if="step==3" enter='fadeIn' leave='fadeOut')
        .architecture.intro.a
          include ./assets/architecture.svg
      eg-transition(v-if="step==4" enter='fadeIn' leave='fadeOut')
        .architecture.intro.a.b
          include ./assets/architecture.svg
      eg-transition(v-if="step==5" enter='fadeIn' leave='fadeOut')
        .architecture.intro.a.b.c
          include ./assets/architecture.svg
      eg-transition(v-if="step==6" enter='fadeIn' leave='fadeOut')
        .architecture.intro.a.b.c.d
          include ./assets/architecture.svg


    //- USECASES
    slide(enter='fadeInDown' leave='fadeOutLeft')
      h1 Use cases
    //- DEV
    slide(:steps=5 enterNext='fadeInRight' leaveNext='fadeOutLeft' leavePrev='fadeOutRight' enterPrev='fadeInLeft')
      .textdev
        h2 Développement
        eg-transition(enter='bounceInLeft' leave='bounceOutLeft')
          li(v-if="step >= 2")
              <b>Audit d'applications</b>
        eg-transition(enter='bounceInLeft' leave='bounceOutLeft')
          li(v-if="step >= 3")
              <b>Visibilité sur les environnements</b>
        eg-transition(enter='bounceInLeft' leave='bounceOutLeft')
          li(v-if="step >= 4")
              <b>Visibilité sur les politiques d'entreprise</b>
        eg-transition(enter='bounceInLeft' leave='bounceOutLeft')
          li(v-if="step >= 5")
              <b>Checklist de passage en production</b>

      .logo.dev.abs
        include ./assets/logo.svg

    //- GOUV
    slide(:steps=4 enterNext='fadeInRight' leaveNext='fadeOutUp' leavePrev='fadeOutRight' enterPrev='fadeInDown')
      .textgouv
        h2 Gouvernance
        eg-transition(enter='bounceInRight' leave='bounceOutRight')
          li(v-if="step >= 2")
              <b>Politiques de sécurité recommandées</b>
        eg-transition(enter='bounceInRight' leave='bounceOutRight')
          li(v-if="step >= 3")
              <b>Mise en places de politiques d'entreprise</b>
        eg-transition(enter='bounceInRight' leave='bounceOutRight')
          li(v-if="step >= 4")
              <b>Visibilité sur les environnements</b>

      .logo.gouv.abs
        include ./assets/logo.svg
    //- SECOPS
    slide(:steps=5 enterNext='fadeInUp' leaveNext='fadeOutRight' leavePrev='fadeOutDown' enterPrev='fadeInRight')
      .textsec
        h2 Sécurité
        eg-transition(enter='bounceInRight' leave='bounceOutRight')
          li(v-if="step >= 2")
              <b>Audit automatisé</b>
        eg-transition(enter='bounceInRight' leave='bounceOutRight')
          li(v-if="step >= 3")
              <b>Assistant a la mise en conformité</b>
        eg-transition(enter='bounceInRight' leave='bounceOutRight')
          li(v-if="step >= 4")
              <b>Evaluation du risque au niveau du SI</b>
        eg-transition(enter='bounceInRight' leave='bounceOutRight')
          li(v-if="step >= 5")
              <b>Analyse de vecteurs d'intrusion</b>
      .logo.sec.abs
        include ./assets/logo.svg
    //- OPS
    slide(:steps=4 enterNext='fadeInLeft' leaveNext='fadeOutUp' leavePrev='fadeOutLeft' enterPrev='fadeInRight')
      .textops
        h2 Opérations
        eg-transition(enter='bounceInLeft' leave='bounceOutLeft')
          li(v-if="step >= 2")
              <b>Audit d'applications</b>
        eg-transition(enter='bounceInLeft' leave='bounceOutLeft')
          li(v-if="step >= 3")
              <b>Assistance au workflow de promotion des applicatifs</b>
        eg-transition(enter='bounceInLeft' leave='bounceOutLeft')
          li(v-if="step >= 4")
              <b>Visibilité sur les checklists de passage en production</b>
      .logo.ops.abs
        include ./assets/logo.svg

    //- BUSINESS MODEL
    slide(steps=5 enter='fadeInUp' leave='fadeOutUp')
      .center.frontpage
        h1 Business Model
      .parent_arrows
        eg-transition(enter='fadeInLeft' leave='fadeOutLeft')
          .arr.plat(v-if='step >= 5')
            include ./assets/arrow.svg
        eg-transition(enter='fadeInRight' leave='fadeOutRight')
          .txt.plat(v-if='step >= 5')
            h6 Platinium
            p Intégration de modules tiers
        eg-transition(enter='fadeInLeft' leave='fadeOutLeft')
          .arr.gold(v-if='step >= 4')
            include ./assets/arrow.svg
        eg-transition(enter='fadeInRight' leave='fadeOutRight')
          .txt.gold(v-if='step >= 4')
            h6 Gold
            p SSO
        eg-transition(enter='fadeInLeft' leave='fadeOutLeft')
          .arr.silver(v-if='step >= 3')
            include ./assets/arrow.svg
        eg-transition(enter='fadeInRight' leave='fadeOutRight')
          .txt.silver(v-if='step >= 3')
            h6 Silver
            p Analyse d'environements
        eg-transition(enter='fadeInLeft' leave='fadeOutLeft')
          .arr.bronze(v-if='step >= 2')
            include ./assets/arrow.svg
        eg-transition(enter='fadeInRight' leave='fadeOutRight')
          .txt.bronze(v-if='step >= 2')
            h6 Bronze
            p Cartographie Kubernetes
            p Audit
    //- COMMERCIAL
    slide(enter='slideInUp' leave='fadeOutUp')
      .center.frontpage
        h1 Commercial
    //- CNCF
    slide(:steps=5, enterNext='fadeInUp' leaveNext='fadeOutLeft' leavePrev='fadeOutRight' enterPrev='fadeInLeft')
      h3 CNCF
      eg-transition(enter='fadeIn' leave='fadeOut')
        .quarter(v-if='step >=2')
          img(src='./assets/team.svg',:style="{height: '2em'}")
          h4 75,671
          p  Contributeurs
      eg-transition(enter='fadeIn' leave='fadeOut')
        .quarter(v-if='step >=3')
          img(src='./assets/startup.svg',:style="{height: '2em'}")
          h4  648
          p  Projets
      eg-transition(enter='fadeIn' leave='fadeOut')
        .quarter(v-if="step >= 4")
         img(src='./assets/kubernetes-stacked-black.svg',:style="{height: '3em'}")
         h4 102
         p Distributions et plateformes certifiées
      eg-transition(enter='fadeIn' leave='fadeOut')
        .quarter(v-if="step >= 5")
         img(src='./assets/group-chat.svg',:style="{height: '2em'}")
         h4 96,875
         p  Inscrits aux cours Kubernetes EdX
    //- CNCF GRAPH
    slide(enter='bounceInRight' leave='slideOutUp')
      h3 Services de projet et niveaux de maturité
      .cncf_graphic
        include ./assets/cncf_graphic.svg

    //- OSS
    slide(steps=4 enter='slideInUp' leave='fadeOutLeft')
      .center.frontpage
        h1 Opensource
        h2
      .parent_oss
        .oss(v-if='(step >= 2)')
          include ./assets/oss.svg
        .support(v-if='(step >= 3)')
          include ./assets/support.svg
        .formation(v-if='(step >= 4)')
          include ./assets/training.svg

    //- FUTUR
    slide(steps=1 enterNext='fadeInRight' leaveNext='fadeOutUp' leavePrev='fadeOutRight' enterPrev='fadeInDown')
      .center.frontpage
        h1 Futur
        h3 Février : Open Source
        h3 Juin : Release privée
    slide(enter='fadeInUp')
      .center.frontpage
        h1 Merci
        h3 Des questions ?
</template>

<script>
import eagle from "eagle.js";
import Logo from "./assets/logo.svg";
import chart from "./Chart";

export default {
  mixins: [eagle.slideshow],
  infos: {
    title: "Introducing Kubees",
    description: "kubees",
    path: "introducing-kubees"
  },
  components: {
    chart
  },
  data: function() {
    return {
      figureDataKubeEnvD: {
        legend: {
          display: true
        },
        animation: {
          duration: 1000
        }
      },
      figureOptionsKubeAdopt: {
        legend: {
          display: true
        },
        animation: {
          duration: 1000
        }
      },
      figureOptionsKubeSec: {
        legend: {
          display: true
        },
        animation: {
          duration: 1000
        }
      }
    };
  },

  methods: {
    figureDataKubeAdopt: function(step) {
      return {
        labels: ["Kubernetes", "Other"],
        datasets: [
          {
            data: [88, 12],
            backgroundColor: [
              "rgba(0, 0, 255, 0.5)",
              "rgba(106, 106, 106, 0.5)"
            ],
            borderColor: ["rgba(25,25,225,1)", "rgba(15, 15, 15, 1)"]
          }
        ]
      };
    },
    figureDataKubeEnv: function(step) {
      return {
        labels: ["Hybrid", "Cloud", "On premise"],
        datasets: [
          {
            data: [53, 30, 17],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(153, 102, 255, 0.2)"
            ],
            borderColor: [
              "rgba(255,99,132,1)",
              "rgba(255, 206, 86, 1)",
              "rgba(153, 102, 255, 1)"
            ]
          }
        ]
      };
    },
    figureDataKubeSec: function(step) {
      return {
        labels: ["Attaques", "Configuration", "Vulnérabilités"],
        datasets: [
          {
            data: [11, 60, 29],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(153, 102, 255, 0.2)"
            ],
            borderColor: [
              "rgba(255,99,132,1)",
              "rgba(255, 206, 86, 1)",
              "rgba(153, 102, 255, 1)"
            ]
          }
        ]
      };
    }
  }
};
</script>

<style lang='scss'>
@import "node_modules/eagle.js/dist/themes/agrume/agrume";
#Kubees {
  .parent_oss {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .parent_oss svg {
    width: 80% !important;
    height: 80% !important;
  }

  .parent_oss svg path {
    fill: black;
  }

  .oss {
    grid-area: 1 / 1 / 2 / 2;
  }
  .support {
    grid-area: 1 / 2 / 2 / 3;
  }
  .formation {
    grid-area: 1 / 3 / 2 / 4;
  }

  .formation svg path[class="cls-1"] {
    fill: none;
  }

  .formation svg path[class="cls-2"] {
    fill: black;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .var_logo svg {
    vertical-align: middle;
  }

  .var_logo.devops svg {
    width: 100%;
    height: 100%;
    margin-bottom: 30%;
  }
  .var_logo.kube svg {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 80%;
  }

  .var_logo {
    grid-area: 1 / 2 / 3 / 4;
  }
  .bee._0 {
    grid-area: 1 / 4 / 2 / 5;
  }
  .bee._1 {
    grid-area: 2 / 4 / 3 / 5;
  }
  .bee._2 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .bee._3 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .var_logo.devops svg g {
    fill: black;
  }

  .bee svg {
    display: block;
    width: 150px;
    height: 150px;
    // fill: rgb(212, 170, 0);;
  }

  .bee._0 svg {
    position: absolute;
    right: 21%;
  }
  .bee._1 svg {
    position: absolute;
    bottom: 20%;
    right: 21%;
  }

  .bee._2 svg {
    transform: scaleX(-1);
  }
  .bee._3 svg {
    position: absolute;
    bottom: 20%;
    transform: scaleX(-1);
  }

  .logo > svg {
    display: block;
    width: 500px;
    height: 500px;
  }

  .logo.initial > svg {
    display: block;
    margin: 0 auto;
    width: 32%;
    height: 32%;
  }

  .eg-slideshow h2 {
    text-align: left;
  }

  .logo > svg > g {
    display: none;
  }
  .logo > svg > g[inkscape\:label="Tilling"] {
    display: block;
  }
  .logo > svg > g[inkscape\:label="Kubernetes"] {
    display: block;
  }

  .logo.initial.total > svg > g {
    display: block;
  }

  // KUBE
  .kube_parent svg {
    margin-left: 60%;
    width: 60%;
    height: 60%;
  }

  .kube_parent {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .container {
    grid-area: 1 / 1 / 2 / 2;
  }
  .code {
    grid-area: 2 / 1 / 3 / 2;
  }
  .yaml {
    grid-area: 3 / 1 / 4 / 2;
  }
  .api {
    grid-area: 1 / 3 / 2 / 4;
  }
  .hardware {
    grid-area: 2 / 3 / 3 / 4;
  }
  .lifecycle {
    grid-area: 3 / 3 / 4 / 4;
  }
  .kube_center_ico {
    grid-area: 2 / 2 / 3 / 3;
  }

  .kube_center_ico svg {
    overflow-y: visible;
    position: relative;
    top: -20%;
  }

  .toright_kube canvas {
    width: 650px !important;
    height: 650px !important;
    margin-left: 25%;
  }

  .toright_env canvas {
    width: 650px !important;
    height: 650px !important;
    margin-left: 22%;
  }

  // ENV

  .toright {
    margin-left: 25%;
  }
  .env_parent {
    p {
      margin-top: 0;
      text-align: left;
      margin-top: 0 !important;
      margin-left: 10%;
      margin-bottom: 0 !important;
    }
    margin-left: 17%;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .pie canvas {
    width: 50%;
  }
  .env_parent svg {
    width: 80%;
  }

  .onprem {
    grid-area: 1 / 1 / 2 / 2;
  }
  .cloud {
    grid-area: 1 / 2 / 2 / 3;
    margin-left: 15%;
  }
  .hybrid {
    grid-area: 1 / 3 / 2 / 4;
    margin-left: 25%;
  }
  .pie {
    grid-area: 2 / 2 / 3 / 3;
  }

  // SECURITY KUBE

  .env_parent_2 {
    p {
      margin-top: 0;
      text-align: left;
      margin-top: 0 !important;
      margin-left: 10%;
      margin-bottom: 0 !important;
    }
    margin-left: 17%;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  // KUBEES
  .kubees_parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .kubees_parent svg {
    width: 80%;
    height: 80%;
  }

  .d_plugin {
    grid-area: 1 / 1 / 2 / 2;
  }
  .d_api {
    grid-area: 2 / 1 / 3 / 2;
  }
  .d_audit {
    grid-area: 1 / 4 / 2 / 5;
  }
  .d_carto {
    grid-area: 2 / 3 / 3 / 4;
  }
  .d_conf {
    grid-area: 2 / 4 / 3 / 5;
  }
  .d_infra {
    grid-area: 2 / 5 / 3 / 6;
  }

  // ARCHITECTURE

  .architecture > svg {
    display: block;
    width: 120%;
    height: 120%;
  }

  .architecture.initial > svg g {
    display: none;
  }

  .architecture.intro > svg g[id="text-secured"] {
    display: block;
  }
  .architecture.intro > svg g[id="green-zone"] {
    display: block;
  }
  .architecture.intro > svg g[id="legende-zone"] {
    display: block;
  }

  .architecture.intro > svg g[id="text-public"] {
    display: block;
  }
  .architecture.intro > svg g[id="orange-zone"] {
    display: block;
  }
  .architecture.intro > svg > g > g > g {
    display: none;
  }

  .architecture.intro.a > svg g[id="kube-secure"] {
    display: block;
  }
  .architecture.intro.a > svg g[id="kube-gateway"] {
    display: block;
  }
  .architecture.intro.a > svg g[id="kube-collector"] {
    display: block;
  }
  .architecture.intro.a > svg g[id="logo-kube-master"] {
    display: block;
  }
  .architecture.intro.a > svg g[id="logo-kube-gateway"] {
    display: block;
  }
  .architecture.intro.a > svg g[id="logo-kube-collector"] {
    display: block;
  }

  .architecture.intro.a.b > svg g[id="text-legende-kubees"] {
    display: block;
  }
  .architecture.intro.a.b > svg g[id="legende-kubees"] {
    display: block;
  }
  .architecture.intro.a.b > svg g[id="collector-box"] {
    display: block;
  }
  .architecture.intro.a.b > svg g[id="gateway-box"] {
    display: block;
  }
  .architecture.intro.a.b > svg g[id="master-box"] {
    display: block;
  }

  .architecture.intro.a.b.c > svg g[id="text-legende-ic"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="legende-ic"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="line-collector-to-pipe"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="line-master-pipe-to-gateway"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="line-collector-pipe-to-gateway"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="line-master-to-gateway-0"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="line-master-to-gateway-1"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="text-master"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="text-gateway"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="text-collector"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="logo-brain"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="collector-gateway-ic"] {
    display: block;
  }
  .architecture.intro.a.b.c > svg g[id="master-gateway-ic"] {
    display: block;
  }

  .architecture.intro.a.b.c.d > svg g[id="app-0"] {
    display: block;
  }
  .architecture.intro.a.b.c.d > svg g[id="app-1"] {
    display: block;
  }
  .architecture.intro.a.b.c.d > svg g[id="line-external-app-to-master"] {
    display: block;
  }
  .architecture.intro.a.b.c.d > svg g[id="line-internal-app-to-master"] {
    display: block;
  }
  .architecture.intro.a.b.c.d > svg g[id="text-legende-app"] {
    display: block;
  }
  .architecture.intro.a.b.c.d > svg g[id="legende-app"] {
    display: block;
  }

  // DEV

  .textdev {
    position: absolute;
    margin-left: 10%;
    text-align: left;
    left: 0;
    right: 0;
  }
  .logo.dev > svg > g[inkscape\:label="Dev"] {
    display: block;
  }
  .logo.dev.abs > svg {
    width: 1500px;
    height: 1500px;
    display: block;
    position: absolute;
    right: -31% !important;
    bottom: -64% !important;
  }

  // GOUV

  .textgouv {
    position: absolute;
    margin-left: 42%;
    text-align: left;
    left: 0;
    right: 0;
  }

  .logo.gouv > svg > g[inkscape\:label="gouv"] {
    display: block;
  }

  .logo.gouv.abs > svg {
    width: 1500px;
    height: 1500px;
    display: block;
    position: absolute;
    left: -31% !important;
    bottom: -64% !important;
  }

  // SEC
  .textsec {
    position: absolute;
    margin-left: 42%;
    text-align: left;
    left: 0;
    right: 0;
  }
  .logo.sec > svg > g[inkscape\:label="Sec"] {
    display: block;
  }
  .logo.sec.abs > svg {
    width: 1500px;
    height: 1500px;
    display: block;
    position: absolute;
    left: -31% !important;
    top: -42% !important;
  }

  // OPS

  .textops {
    position: absolute;
    margin-left: 10%;
    text-align: left;
    left: 0;
    right: 0;
  }

  .logo.ops > svg > g[inkscape\:label="Ops"] {
    display: block;
  }
  .logo.ops.abs > svg {
    width: 1500px;
    height: 1500px;
    display: block;
    position: absolute;
    right: -31% !important;
    top: -42% !important;
  }

  // BUSINESS MODEL

  .parent_arrows {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    grid-template-rows: repeat(4, 0.55fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .txt h6 {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    // margin-bottom: 5%;
    // margin-top: 5%;
  }

  .txt p {
    margin-block-start: 0.33em !important;
    margin-block-end: 0.33em !important;
  }

  .arr.plat {
    grid-area: 1 / 1 / 2 / 2;
  }
  .arr.gold {
    grid-area: 2 / 1 / 3 / 2;
  }
  .arr.silver {
    grid-area: 3 / 1 / 4 / 2;
  }
  .arr.bronze {
    grid-area: 4 / 1 / 5 / 2;
  }
  .txt.plat {
    grid-area: 1 / 2 / 2 / 3;
  }
  .txt.gold {
    grid-area: 2 / 2 / 3 / 3;
  }
  .txt.silver {
    grid-area: 3 / 2 / 4 / 3;
  }
  .txt.bronze {
    grid-area: 4 / 2 / 5 / 3;
  }

  .arr svg {
    width: 100px;
    height: 100px;
    stroke-width: 2%;
    stroke: black;
  }

  .arr.gold svg {
    fill: gold;
  }
  .arr.silver svg {
    fill: silver;
  }
  .arr.plat svg {
    fill: #e5e4e2;
  }
  .arr.bronze svg {
    fill: #b87333;
  }

  .txt {
    text-align: right;
  }

  // CNCF
  .quarter {
    text-align: center;
    p {
      margin-top: 0;
      text-align: center;
    }
    h4 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  // CNCFGRAPH
  .cncf_graphic > svg {
    margin-left: -20%;
    display: block;
    width: 150%;
    height: 150%;
  }
  // Right of graphic
  .cncf_graphic > svg > path.cls-1 {
    fill: #564000 !important;
  }
  // Graduate
  .cncf_graphic > svg > path.cls-2 {
    fill: #ac8000 !important;
  }
  // Sandbox
  .cncf_graphic > svg > path.cls-3 {
    fill: #ffca2e !important;
  }
  // End of graphic
  .cncf_graphic > svg > path.cls-4 {
    fill: #2b2000 !important;
  }
  // Incubating
  .cncf_graphic > svg > path.cls-5 {
    fill: #ffbf03 !important;
  }
  // Late of majority
  .cncf_graphic > svg > path.cls-6 {
    fill: #ac8000 !important;
  }
  // Incubating bar
  .cncf_graphic > svg > path.cls-7 {
    fill: #ffbf03 !important;
  }
  .cncf_graphic > svg > path.cls-8 {
    fill: #fff !important;
  }
  .cncf_graphic > svg > path.cls-9 {
    opacity: 0.5;
  }
  .cncf_graphic > svg > path.cls-10 {
    fill: #999;
  }
}
</style>
